<template>
  <div class="modal" v-if="isShow" @click="hide">
    <img :src="images[0]" @click.stop />
  </div>
</template>
<script>
export default {
  name: "ImageViewer",
  props: {
    images: {
      type: Array
    },
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      current: 0
    };
  },
  watch: {
    images: function() {
      this.current = 0;
    }
  },
  methods: {
    hide() {
      this.$emit("update:isShow", false);
    }
  }
};
</script>
<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
