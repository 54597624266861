<template>
  <span class="char-count">{{curLen}}/{{max}}</span>
</template>

<script>
export default {
  name: "char-count",
  props: {
    text: {
      type: String,
      default: ""
    },
    max: {
      type: Number,
      default: 0
    }
  },
  computed: {
    curLen() {
      return this.text.length;
    }
  }
};
</script>

<style lang="scss" scoped>
.char-count {
  margin-left: 5px;
  color: #909090;
  font-size: 13px;
}
</style>