<template>
  <div class="editor">
    <quill-editor ref="editor" v-model="innerContent" :options="options" @ready="onReady"></quill-editor>
    <el-upload action :before-upload="onBeforeUpload" style="display:none;">
      <el-button ref="uploadBtn">点击上传</el-button>
    </el-upload>
  </div>
</template>

<script>
export default {
  name: "editor",
  props: {
    content: {
      type: String,
      default: ""
    },
    uploadUrl: {
      type: String,
      default: "/api/lector/public/file"
    }
  },
  data() {
    return {
      quillEditor: null, // quill-editor 对象
      titleConfig: {
        "ql-bold": "加粗",
        "ql-color": "颜色",
        "ql-font": "字体",
        "ql-code": "插入代码",
        "ql-italic": "斜体",
        "ql-link": "添加链接",
        "ql-background": "背景颜色",
        "ql-size": "字体大小",
        "ql-strike": "删除线",
        "ql-script": "上标/下标",
        "ql-underline": "下划线",
        "ql-blockquote": "引用",
        "ql-header": "标题",
        "ql-indent": "缩进",
        "ql-list": "列表",
        "ql-align": "文本对齐",
        "ql-direction": "文本方向",
        "ql-code-block": "代码块",
        "ql-formula": "公式",
        "ql-image": "图片",
        "ql-video": "视频",
        "ql-clean": "清除字体样式"
      },
      options: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
            ["blockquote", "code-block"], // 引用  代码块
            [{ header: 1 }, { header: 2 }], // 1、2 级标题
            [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
            [{ script: "sub" }, { script: "super" }], // 上标/下标
            [{ indent: "-1" }, { indent: "+1" }], // 缩进
            // [{'direction': 'rtl'}],                         // 文本方向
            [{ size: ["small", false, "large", "huge"] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
            [{ font: [] }], // 字体种类
            [{ align: [] }], // 对齐方式
            ["clean"], // 清除文本格式
            ["link", "image", "video"] // 链接、图片、视频
          ]
        },
        placeholder: "请输入内容..."
      },
      innerContent: "",
      uploadType: ""
    };
  },
  created() {},
  mounted() {
    this.addQuillTitle();
  },
  watch: {
    innerContent(val) {
      this.$emit("update:content", val);
    },
    content(val) {
      this.innerContent = val;
    }
  },
  methods: {
    // method
    getContentLen() {
      const str = this.quillEditor.container.innerText;
      const strLen = str.length;
      if (strLen == 1 && str.trim().length == 0) {
        return 0;
      }
      return strLen;
    },
    // on event
    onReady(quill) {
      this.quillEditor = quill;
      const toolbar = quill.getModule("toolbar");
      toolbar.addHandler("image", state => {
        if (!state) return;
        this.uploadType = "image";
        this.$refs.uploadBtn.$el.click();
      });
      toolbar.addHandler("video", state => {
        if (!state) return;
        this.uploadType = "video";
        this.$refs.uploadBtn.$el.click();
      });
    },
    onBeforeUpload(file) {
      const formData = new FormData();
      formData.append("file", file);
      this.$http.post(this.uploadUrl, formData).then(data => {
        const selection = this.quillEditor.getSelection();
        this.quillEditor.insertEmbed(
          selection ? selection.index : 0,
          this.uploadType,
          data.path
        );
      });
      return false;
    },
    // other
    addQuillTitle() {
      const oToolBar = document.querySelector(".ql-toolbar"),
        aButton = oToolBar.querySelectorAll("button"),
        aSelect = oToolBar.querySelectorAll("select");
      aButton.forEach(item => {
        if (item.className === "ql-script") {
          item.value === "sub" ? (item.title = "下标") : (item.title = "上标");
        } else if (item.className === "ql-indent") {
          item.value === "+1"
            ? (item.title = "向右缩进")
            : (item.title = "向左缩进");
        } else {
          item.title = this.titleConfig[item.classList[0]];
        }
      });
      aSelect.forEach(item => {
        item.parentNode.title = this.titleConfig[item.classList[0]];
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.editor {
  line-height: normal;
  /deep/ .ql-container {
    min-height: 100px;
  }
}
</style>